import { definePreset } from '@primeuix/themes';
import Lara from '@primeuix/themes/lara';

export const VRStarPreset = definePreset(Lara, {
  components: {
    accordion: {
      header: {
        color: '{text.color}',
      },
    },
    avatar: {
      root: {
        background: '{primary.500}',
      },
    },
    button: {
      colorScheme: {
        light: {
          text: {
            secondary: {
              activeBackground: '{surface.200}',
              hoverBackground: '{surface.100}',
              color: '{secondary.500}',
            },
          },
          outlined: {
            secondary: {
              activeBackground: '{surface.200}',
              hoverBackground: '{surface.100}',
              borderColor: '{secondary.500}',
              color: '{secondary.500}',
            },
          },
          root: {
            secondary: {
              background: '{secondary.500}',
              hoverBackground: '{secondary.300}',
              activeBackground: '{secondary.100}',
              borderColor: '{secondary.500}',
              hoverBorderColor: '{secondary.300}',
              activeBorderColor: '{secondary.100}',
              color: '{surface.50}',
              hoverColor: '{surface.50}',
              activeColor: '{surface.50}',
            },
          },
        },
      },
      root: {
        iconOnlyWidth: '2.357rem',
        paddingX: '1rem',
        paddingY: '.5rem',
        sm: {
          paddingX: '.875 * {button.root.paddingX}',
          paddingY: '.875 * {button.root.paddingY}',
        },
        lg: {
          paddingX: '1.25 * {button.root.paddingX}',
          paddingY: '1.25 * {button.root.paddingY}',
        },
      },
    },
    card: {
      body: {
        padding: '1rem',
      },
      subtitle: {
        color: '{secondary.500}',
      },
    },
    carousel: {
      indicator: {
        background: '{surface.200}',
        hoverBackground: '{surface.300}',
      },
    },
    chip: {
      root: {
        paddingX: '.5rem',
        paddingY: '.5rem',
      },
      colorScheme: {
        light: {
          root: {
            color: '{secondary.500}',
            background: '{shade.300}',
          },
        },
      },
    },
    checkbox: {
      root: {
        width: '20px',
        height: '20px',
      },
      icon: {
        size: '14px',
      },
    },
    datatable: {
      bodyCell: {
        padding: '1rem',
      },
      colorScheme: {
        light: {
          headerCell: {
            background: '{surface.100}',
            hoverBackground: '{surface.200}',
            selectedColor: '{secondary.100}',
            selectedBackground: '{surface.100}',
          },
          footer: {
            background: '{surface.100}',
          },
        },
      },
      header: {
        padding: '1rem',
      },
      headerCell: {
        padding: '1rem',
      },
      sortIcon: {
        color: '{surface.600}',
        size: '1rem',
      },
    },
    divider: {
      horizontal: {
        margin: '1rem 0',
        padding: '0 1rem',
      },
      vertical: {
        margin: '0 1rem',
        padding: '1rem 0',
      },
    },
    editor: {
      colorScheme: {
        light: {
          toolbar: {
            background: '{surface.100}',
          },
        },
      },
      toolbar: {
        borderColor: '{surface.300}',
      },
      toolbarItem: {
        color: '{surface.600}',
        hoverColor: '{surface.700}',
        activeColor: '{secondary.100}',
      },
      content: {
        borderColor: '{surface.300}',
      },
    },
    fileupload: {
      content: {
        highlightBorderColor: '{surface.200}',
      },
      file: {
        borderColor: '#c3cad2',
      },
    },
    select: {
      option: {
        focusColor: '{secondary.700}',
        selectedFocusColor: '{text.color}',
        selectedFocusBackground: '{surface.200}',
      },
    },
    message: {
      root: {
        borderWidth: '0',
      },
      content: {
        padding: '.5rem .75rem',
      },
      icon: {
        size: '1rem',
      },
      simple: {
        content: {
          padding: '{form.field.paddingY} {form.field.paddingX}',
        },
      },
      colorScheme: {
        light: {
          info: {
            background: '{infoMessageBg}',
            color: '{shade.0}',
          },
          success: {
            background: '{successMessageBg}',
            color: '{shade.0}',
          },
          warn: {
            background: '{warMessageBg}',
            color: '{text.color}',
          },
          error: {
            background: '{errorMessageBg}',
            color: '{shade.0}',
          },
        },
      },
    },
    paginator: {
      navButton: {
        borderRadius: '{border.radius.md}',
        selectedColor: '{text.color}',
        selectedBackground: '{primary.500}',
      },
    },
    panel: {
      header: {
        padding: '1rem',
      },
      content: {
        padding: '1rem',
      },
      footer: {
        padding: '.5rem 1rem',
      },
    },
    password: {
      colorScheme: {
        light: {
          strength: {
            weakBackground: '{errorMessageBg}',
            mediumBackground: '{warMessageBg}',
            strongBackground: '{successMessageBg}',
          },
        },
      },
    },
    radiobutton: {
      root: {
        height: '1.25rem',
        width: '1.25rem',
      },
    },
    stepper: {
      stepTitle: {
        activeColor: '{secondary.500}',
        fontWeight: '700',
      },
      stepNumber: {
        activeColor: '{secondary.500}',
        activeBorderColor: '#C8C8C8',
      },
    },
    tabs: {
      colorScheme: {
        light: {
          tab: {
            background: 'none',
            activeBackground: 'none',
            hoverBackground: 'none',
          },
        },
      },
      tablist: {
        borderWidth: '0 0 2px 0',
      },
      tab: {
        activeBorderColor: '{secondary.500}',
        activeColor: '{secondary.500}',
        borderColor: '{content.border.color}',
        borderWidth: '0 0 2px 0',
        hoverBorderColor: '{surface.500}',
        margin: '0 0 -2px 0',
      },
    },
    toast: {
      root: {
        borderWidth: '0',
      },
      colorScheme: {
        light: {
          info: {
            background: 'color-mix(in srgb, #039BE5, transparent 10%)',
            color: '{shade.0}',
            detailColor: '{shade.0}',
          },
          success: {
            background: 'color-mix(in srgb, #43A047, transparent 10%)',
            color: '{shade.0}',
            detailColor: '{shade.0}',
          },
          warn: {
            background: 'color-mix(in srgb, #FFB300, transparent 10%)',
            color: '{text.color}',
            detailColor: '{text.color}',
          },
          error: {
            background: 'color-mix(in srgb, #E53935, transparent 10%)',
            color: '{shade.0}',
            detailColor: '{shade.0}',
          },
        },
      },
    },
    togglebutton: {
      root: {
        checkedBackground: '{primary.color}',
        checkedBorderColor: '{primary.color}',
        checkedColor: '{text.color}',
        fontWeight: '700',
      },
    },
    tooltip: {
      colorScheme: {
        light: {
          root: {
            background: '{text.color}',
            color: '{shade.0}',
          },
        },
      },
    },
  },
  primitive: {
    shade: {
      0: '#FFFFFF',
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      950: '#121212',
    },
    sunny: {
      50: '#f8ffde',
      100: '#f8ffd8',
      300: '#f0ffa5',
      500: '#E7FF72',
      700: '#deff3f',
      900: '#d6ff0c',
    },
    night: {
      100: '#195da9',
      300: '#13447c',
      500: '#0C2C50',
      700: '#09203a',
      900: '#051424',
    },
    tertiary: {
      100: '#d0f3e1',
      300: '#a8e8c7',
      500: '#80ddad',
      700: '#58d293',
      900: '#34c379',
    },
    info: {
      messageBg: '#039BE5',
    },
    success: {
      100: '#92cf94',
      300: '#6ec071',
      500: '#4caf50',
      700: '#43a047',
      900: '#388e3c',
      messageBg: '#43A047',
    },
    warn: {
      100: '#ffdb6d',
      300: '#ffce3a',
      500: '#ffC107',
      700: '#ffb300',
      900: '#ffa000',
      messageBg: '#FFB300',
    },
    danger: {
      100: '#f7a2a2',
      300: '#f37373',
      500: '#ef4444',
      700: '#e53935',
      900: '#d32f2f',
      messageBg: '#E53935',
    },
  },
  semantic: {
    navigation: {
      item: {
        padding: '.75rem 1rem',
      },
    },
    colorScheme: {
      light: {
        navigation: {
          item: {
            activeBackground: '{surface.200}',
            focusBackground: '{surface.100}',
            focusColor: '{text.color}',
            activeColor: '{text.color}',
            hoverColor: '{text.color}',
            icon: {
              color: '{shade.600}',
              focusColor: '{text.color}',
              activeColor: '{secondary.500}',
            },
          },
        },
        focusRing: {
          shadow: '0 0 0 .2rem {primary.300}',
        },
        formField: {
          borderColor: '{surface.400}',
          hoverBorderColor: '{secondary.300}',
          focusBorderColor: '{secondary.500}',
          color: '{secondary.500}',
          disabledColor: '{secondary.500}',
          placeholderColor: '{surface.600}',
          iconColor: '{secondary.500}',
          invalidBorderColor: '{surface.400} {surface.400} {red.400} {surface.400}',
        },
        highlight: {
          color: '{secondary.900}',
          focusBackground: 'color-mix(in srgb, {secondary.900}, transparent 24%)',
        },
        primary: {
          contrastColor: '{secondary.500}',
        },
        surface: {
          0: '{shade.0}',
          50: '{shade.50}',
          100: '{shade.100}',
          200: '{shade.200}',
          300: '{shade.300}',
          400: '{shade.400}',
          500: '{shade.500}',
          600: '{shade.600}',
          700: '{shade.700}',
          800: '{shade.800}',
          900: '{shade.900}',
          950: '{shade.950}',
        },
        text: {
          color: '{secondary.500}',
        },
      },
    },
    disabledOpacity: '0.8',
    formField: {
      paddingX: '.5rem',
      paddingY: '.5rem',
    },
    primary: {
      50: '{sunny.50}',
      100: '{sunny.100}',
      200: '{sunny.100}',
      300: '{sunny.300}',
      400: '{sunny.300}',
      500: '{sunny.500}',
      600: '{sunny.700}',
      700: '{sunny.700}',
      800: '{sunny.900}',
      900: '{sunny.900}',
      950: '{sunny.900}',
    },
    secondary: {
      100: '{night.100}',
      300: '{night.300}',
      500: '{night.500}',
      700: '{night.700}',
      900: '{night.900}',
    },
  },
});
