{
  "account": {
    "emailConfirmation": {
      "failed": {
        "content": "An error occurred during confirmation.\nYour account may have already been activated?",
        "headline": "Error during confirmation"
      },
      "loading": {
        "content": "processing"
      },
      "succeeded": {
        "content": {
          "enduser": "Your account has been successfully confirmed.",
          "user": "Your account has been successfully confirmed.\nYou can now close this window."
        },
        "cta": "Login now",
        "headline": "Welcome"
      }
    },
    "passwordReset": {
      "headline": "Change password",
      "succeeded": {
        "cta": "Login now",
        "message": "Your password has been successfully changed."
      }
    }
  },
  "bankManagement": {
    "basicDataTab": "General",
    "editHeadline": "Edit bank",
    "fields": {
      "active": {
        "description": "Determines whether the bank is available for end users (in the mobile app).",
        "label": "Bank active?"
      },
      "bic": "BIC",
      "disabledCategories": {
        "description": "Content categories hidden from your users",
        "label": "Disabled categories",
        "maxLengthErrorMessage": "Minimum of three categories must be active",
        "placeholder": "Select categories"
      },
      "email": "Email",
      "estateUser": {
        "description": "Enter the user name that is used to transfer the properties from OnOffice (e.g. vr-immo-xyz)",
        "label": "Real Estates Username"
      },
      "meinPlus": {
        "apiKey": {
          "label": "API Key"
        },
        "clientId": {
          "label": "Client ID"
        },
        "clientSecret": {
          "label": "Client secret"
        },
        "description": "By entering valid access data, you connect the bank to the MeinPlus interface."
      },
      "phone": "Phone",
      "title": "Bank name"
    },
    "links": {
      "addedValueBankLinks": {
        "addLink": "Add Link",
        "descriptionMaxLengthHint": "max {length} chars",
        "disclaimerPlaceholder": "Shown to users when they click the provided link",
        "fields": {
          "description": "Description",
          "disclaimer": "Disclaimer",
          "title": "Title",
          "url": "Web link"
        },
        "headline": "Marketing Links",
        "headlineHint": "There is a maximum of four concurrent marketing links",
        "iconTitle": "Icon",
        "optional": "Optional",
        "remove": "Delete",
        "selectIcon": "Select icon"
      },
      "appointmentBooking": "Appointment Booking",
      "gtc": "GTC",
      "headline": "General links",
      "privacyPolicy": "Privacy policy",
      "terms": "Terms",
      "termsOfUse": "Terms of use"
    },
    "logo_upload": "Bank logo",
    "meinPlus": {
      "headline": "MeinPlus",
      "invalidCredentials": {
        "fieldMessage": "One or more \"MeinPlus\" fields are invalid. Please check and resubmit the credentials.",
        "toastMessage": "Invalid MeinPlus Credentials"
      },
      "title": "Credentials"
    },
    "menu": {
      "banks": "Banks"
    },
    "profileHeadline": "Bank Profile",
    "videoCalls": {
      "afterHoursPhone": "Telephone number Service Center (outside service hours)",
      "businessHours": {
        "break": "Break",
        "headline": "Service times",
        "hours": "from/to"
      },
      "headline": "Service hours",
      "videocallsAvailable": "Video calls available?"
    }
  },
  "bankSelect": {
    "bankLess": {
      "alert": "Caution, you are in no-bank mode."
    },
    "control": {
      "groups": {
        "banks": "Banks"
      },
      "label": "Mode",
      "placeholder": "Select bank",
      "vrstarOptionTitle": "Work with VR Star"
    },
    "page": {
      "description": "Please select a bank first to be able to work in this area.",
      "headline": "Select bank"
    }
  },
  "components": {
    "googleMaps": {
      "locationError": "Error accessing your location. Check the location permissions.",
      "inactiveMapMessage": "The map will be active after entering the address above.",
      "myLocationButton": "Use my current location"
    }
  },
  "contentFeed": {
    "attachments": {
      "description": "Add up to {maxFiles} additional files (images or PDFs). Maximum file size of {fileSize} MB",
      "headline": "Attachments"
    },
    "contentType": {
      "article": "Article",
      "file": "File (e.g. magazine)",
      "link": "External article (link)",
      "short": {
        "article": "Article",
        "file": "File",
        "link": "Link"
      }
    },
    "create_title": "Create new record",
    "edit_title": "Edit record",
    "errors": {
      "requiredContent": "This field is required for the content type \"{contentType}\""
    },
    "fields": {
      "backLink": "Link",
      "contentType": "Content type",
      "type": "Type"
    },
    "file": {
      "attachments": {
        "description": "Add the file in PDF format. Maximum file size of {fileSize} MB",
        "headline": "File"
      },
      "image": {
        "description": "Maximum file size of {fileSize} MB. Optional, if not specified, an attempt is made to automatically create an image from the PDF file."
      }
    },
    "header_title": "News",
    "image": {
      "description": "Maximum file size of {fileSize} MB",
      "headline": "Preview image"
    },
    "photoCopyright": "Image copyright",
    "tags": {
      "culture": "Culture",
      "estate": "Real estate",
      "event": "Event",
      "music": "Music",
      "news": "News",
      "politics": "Politics",
      "sport": "Sport"
    },
    "type": {
      "bank": "Bank",
      "rss": "RSS",
      "vrstar": "VRStar"
    }
  },
  "copyrights": {
    "headers": {
      "attachment_copyrights": "Update attachment copyrights",
      "image_copyrights": "Update image copyrights"
    },
    "labels": {
      "image": "Image copyright"
    },
    "no_images": "No images found"
  },
  "entity_history": {
    "history": "Changelog",
    "record_history": "Log change",
    "terms": {
      "changes": "Changes",
      "record_created": "Record created",
      "record_deleted": "Record deleted"
    }
  },
  "event": {
    "additionalLinks": "Additional Links",
    "additionalLinks_title": "Title",
    "additionalLinks_url": "Link",
    "attachments": "Attachments",
    "audience": "Audience",
    "cancelled": {
      "button": "Mark as cancelled",
      "label": "Cancelled",
      "revertButton": "Revert state",
      "success": "The \"cancelled\" status updated"
    },
    "categories": "Categories",
    "checkoutURL": "Checkout URL",
    "dates": {
      "headline": "Period",
      "modalHeadline": "Recurring event",
      "recurrence": {
        "label": "Recurrence"
      },
      "setTime": "Set time",
      "setTimeSuccess": "Time successfully saved",
      "unknownTime": "Unknown time"
    },
    "declineMessage": "Message",
    "description": "Description",
    "disabledAccessible": "Barrier-free access",
    "dresscode": "Dresscode",
    "dresscodeDescription": "Dresscode description",
    "emergencyContact": "Emergency contact",
    "endDateTime": "Close date",
    "maxPrice": "Maximum price",
    "minPrice": "Minimum price",
    "openingDateTime": "Opening date",
    "organizer": "Organizer",
    "photoCopyright": "Image copyright",
    "photoURL": "Image",
    "placeholder": {
      "audience": "Select audience",
      "dresscode": "Select dresscode",
      "organizer": "Select organizer",
      "venue": "Select venue"
    },
    "price": "Price",
    "priceType": {
      "freeOfCharge": "free of charge",
      "unknown": "no price indication",
      "withCosts": "with costs"
    },
    "soldOut": {
      "button": "Mark as sold out",
      "label": "Sold Out",
      "revertButton": "Revert state",
      "success": "The \"sold out\" status updated"
    },
    "sponsoredByBank": {
      "label": "Bank sponsored"
    },
    "startDateTime": "Start date",
    "state": "Current state",
    "statusFlags": "Event status",
    "title": "Title",
    "venue": "Venue",
    "weatherProtection": "Weather Protection"
  },
  "event_management_page": {
    "abort_attachment_edit": "Abort editing attachments",
    "add_attachment": "Attachments",
    "add_attachments": "Add attachment",
    "add_organizer": "Add organizer",
    "add_venue": "Add venue",
    "additionalLinks": {
      "addLink": "Add Link",
      "headline": "Additional Links",
      "headlineHint": "There is a maximum of four concurrent additional links"
    },
    "attachment_disclaimer": "Add up to {maxFiles} additional files to your event (images or PDFs). Max file size of {fileSize} MB",
    "description": "Description",
    "edit_attachments": "Edit attachments",
    "event_details": "Event details",
    "file_preview": "Attachments",
    "headlines": {
      "createEvent": "Create new Event",
      "createOrganizer": "Create new Organizer",
      "createVenue": "Create new Venue",
      "editEvent": "Edit Event",
      "editOrganizer": "Edit Organizer",
      "editVenue": "Edit Venue"
    },
    "image_preview": "Image",
    "import": {
      "downloadTemplateCta": "Download Template",
      "headline": "Import events"
    },
    "list": {
      "address": "Address",
      "city": "City",
      "country": "Country",
      "email": "Email",
      "end_time": " End time",
      "image": "Image",
      "organizer_title": "Organizer",
      "price": "Price",
      "start_time": "Start time",
      "title": "Title",
      "venue_title": "Venue",
      "zip": "Zip code"
    },
    "list_events": "List events",
    "list_organizers": "List organizers",
    "mainOrganizerRequired": "At least one organizer must be main.",
    "meeting_points": {
      "addMeetingPoint": "Add meeting point",
      "title": "Meeting Points"
    },
    "menu": {
      "events": "Events",
      "import": "Import",
      "organizers": "Organizers",
      "venues": "Venues"
    },
    "no_events_found": "No events found",
    "prices": {
      "additionalPrices": {
        "addCta": "Add price",
        "description": "Description",
        "headline": "Additional price categories",
        "price": "Price",
        "title": "Title"
      },
      "defaultPrice": "Default price",
      "defaultPriceDescription": "Description for standard price",
      "headline": "Prices"
    },
    "resource_id_missing": "Resource ID is missing",
    "restrictions": "Max file size of {size} MB",
    "upload_image": "Image"
  },
  "file_upload": {
    "error": {
      "max_files": "You can only upload {maxFiles} files at a time.",
      "requirements": "File needs to be a {filetype} and be less than {size}.",
      "upload_error": "Error uploading file. Please try again later.",
      "wrong_file_type": "Please recheck the upload requirements and try again."
    },
    "idle": "Drag {'&'} Drop your files or {'<'}span class='filepond--label-action'{'>'} Browse {'<'}span{'>'}",
    "max_file_size": "Maximum file size is {filesize}.",
    "max_file_size_exceeded": "File is too large."
  },
  "firebase_error": {
    "auth/invalid-email": "Username or password are incorrect",
    "auth/invalid-login-credentials": "The user name or password is incorrect",
    "auth/user-disabled": "The user corresponding to the given email has been disabled.",
    "auth/user-not-found": "No user corresponding to the given email.",
    "auth/wrong-password": "Username or password are incorrect",
    "unknown": "An unknown error occurred. Please try again later",
    "wrong_credentials": "Username or password are incorrect"
  },
  "forms": {
    "confirm_password": "Confirm password",
    "contact": {
      "description": "How can interested parties reach you? For example, provide a phone number or email address.",
      "label": "Contact"
    },
    "content": "Content",
    "contentType": "Content type",
    "create_new_user": "Create new user",
    "description": "Description",
    "detach_coordinates": "Detach coordinates",
    "discountedDebit": {
      "description": "when paying with the golden girocard",
      "label": "Discount"
    },
    "edit_user": "Edit user",
    "email": "Email",
    "firstname": "First name",
    "headers": {
      "organizer": {
        "details": "Organizer details"
      },
      "venue": {
        "details": "Venue details"
      }
    },
    "new_password": "New password",
    "partner": "MyPlus Partner",
    "password": "Password",
    "placeholder": {
      "email": "E-mail address",
      "firstname": "First name",
      "password": "Password",
      "select_bank": "Select a bank",
      "select_partner": "Select partner",
      "select_role": "Select role",
      "surname": "Last name",
      "tags": "Select tags",
      "username": "Username",
      "valid_for": "Valid for"
    },
    "price": "Price",
    "publish_date": "Publish date",
    "reveal_password": "Reveal password",
    "role": "Role",
    "start_date": "Event start date",
    "subtitle": "Subtitle",
    "surname": "Surname",
    "tags": "Tags",
    "title": "Title",
    "type": "Type",
    "username": "Username",
    "valid_for": "Type",
    "validity": {
      "day": "Daily offer",
      "month": "Monthly offer",
      "week": "Weekly offer"
    },
    "validityStart": {
      "description": {
        "day": "The offer is valid only on this day",
        "month": "The offer is valid for one month from this date",
        "week": "The offer is valid for one week from this date"
      },
      "label": "Validity start"
    }
  },
  "generic": {
    "back": "back",
    "bank": "Bank",
    "cancel": "Cancel",
    "confirm": "Confirm",
    "confirmation": "Confirmation",
    "content_categories": {
      "clubs": "Clubs",
      "estate": "Real estate",
      "events": "Events",
      "feed": "Feeds",
      "partners": "Partners and Offers",
      "seniors": "Senior Care"
    },
    "continue": "Continue",
    "copyright_saved": "Copyrights successfully saved",
    "create": "Create",
    "createNew": "New",
    "data_table": {
      "confirm": "Confirm",
      "contact": "Contact",
      "date": "Date",
      "has_been_accepted": "Accepted?",
      "open_immo_object_id": "Object number",
      "page": "Page",
      "price": "Price",
      "result_count": "Showing {min}-{max} out of {total} results",
      "tags": "Tags",
      "title": "Title",
      "type": "Type",
      "validity_start": "Validity start"
    },
    "delete": "Delete",
    "deleteConfirmation": {
      "header": "Confirm Deletion",
      "message": "Are you sure you want to delete this resource?"
    },
    "discard": {
      "message": "Are you sure you want to discard changes?"
    },
    "edit": "Edit",
    "editor": {
      "bold": "Bold",
      "increaseIndent": "Increase indent",
      "italic": "Italic",
      "link": "Link",
      "orderedList": "Ordered List",
      "reduceIndent": "Reduce indent",
      "size": {
        "h1": "Heading 1",
        "h2": "Heading 2",
        "normal": "Normal"
      },
      "underline": "Underline",
      "unorderedList": "Unordered List"
    },
    "error": "Error",
    "errors": {
      "bicMismatch": "This import file was created for another bank.",
      "invalidRequestBody": "The request was invalid. Please try again later.",
      "unknownError": "An unknown error has occurred. Please try again later."
    },
    "filepond": {
      "fileValidateTypeLabelExpectedTypes": "",
      "imageValidateSizeLabelExpectedMaxResolution": "",
      "imageValidateSizeLabelExpectedMaxSize": "",
      "imageValidateSizeLabelExpectedMinResolution": "",
      "imageValidateSizeLabelExpectedMinSize": "",
      "imageValidateSizeLabelFormatError": "",
      "imageValidateSizeLabelImageResolutionTooHigh": "",
      "imageValidateSizeLabelImageResolutionTooLow": "",
      "imageValidateSizeLabelImageSizeTooBig": "",
      "imageValidateSizeLabelImageSizeTooSmall": "",
      "labelButtonAbortItemLoad": "",
      "labelButtonAbortItemProcessing": "",
      "labelButtonProcessItem": "",
      "labelButtonRemoveItem": "",
      "labelButtonRetryItemLoad": "",
      "labelButtonRetryItemProcessing": "",
      "labelButtonUndoItemProcessing": "",
      "labelFileLoadError": "",
      "labelFileLoading": "",
      "labelFileProcessing": "",
      "labelFileProcessingAborted": "",
      "labelFileProcessingComplete": "",
      "labelFileProcessingError": "",
      "labelFileProcessingRevertError": "",
      "labelFileRemoveError": "",
      "labelFileSizeNotAvailable": "",
      "labelFileTypeNotAllowed": "",
      "labelFileWaitingForSize": "",
      "labelInvalidField": "",
      "labelMaxFileSize": "",
      "labelMaxFileSizeExceeded": "",
      "labelMaxTotalFileSize": "",
      "labelMaxTotalFileSizeExceeded": "",
      "labelTapToCancel": "",
      "labelTapToRetry": "",
      "labelTapToUndo": "",
      "lableIdle": "Drag {'&'} Drop your files or {'<'}span class='filepond--label-action'{'>'} browse {'<'}span{'>'}"
    },
    "filter": {
      "apply": "Apply",
      "buttonLabel": "Filter"
    },
    "formValidationFailed": "Error during validation, please check the form",
    "freeOfCharge": "free of charge",
    "go_back": "Go back",
    "image": "Image",
    "imageCopyrightsHint": {
      "buttonLabel": "Image copyright",
      "tooltipContent": "Click on the preview of an image to store image rights.{'<'}br{'>'}{'<'}br{'>'}{'<'}strong{'>'}Attention{'<'}/strong{'>'}{'<'}br{'>'}For newly uploaded images must be saved once before!"
    },
    "linkedBank": "You are linked to the bank {'<'}strong{'>'}{bank}{'<'}/strong{'>'}",
    "listEmpty": "No records found",
    "locales": {
      "de": "German",
      "en": "English"
    },
    "logout": "Log out",
    "no": "no",
    "ok": "Ok",
    "open_page": "Open page",
    "publicHoliday": "Holiday",
    "publish": "Publish",
    "published": "Record published",
    "recordStateInfo": {
      "ACTIVE": "Active",
      "descriptions": {
        "ACTIVE": "The post has been confirmed and is now displayed in the app.",
        "DRAFT": "The post has not yet been submitted and can still be edited.",
        "REVIEW": "The post has been submitted and is awaiting confirmation from the bank."
      },
      "DRAFT": "Draft",
      "label": "Record state",
      "REVIEW": "Waiting for Review"
    },
    "replace_changes": "Are you sure you want to replace current changes?",
    "reset": "Reset",
    "save": "Save",
    "saved": "Record saved.",
    "search": "Search",
    "searchPlaceholder": "Search by",
    "selectPlaceholder": "Please select",
    "submitForReview": "Submit For Review",
    "submittedForReview": "Record submitted for review",
    "success": "Success",
    "timepicker": {
      "cancel": "cancel",
      "select": "save"
    },
    "title": "Title",
    "unPublish": "Revert to draft",
    "unPublished": "Record reverted to draft",
    "unsaved_changes": "You have unsaved changes, are you sure you want to go back?",
    "unsaved_changes_header": "Unsaved changes",
    "warning": "Warning",
    "yes": "yes",
    "Yes": "Yes"
  },
  "global_modals": {
    "choose_password": "Choose a password that includes a combination of uppercase and lowercase letters, numbers, and special characters to ensure its strength.",
    "password_change_required": "Password change required",
    "reset_password": "Reset password",
    "reset_password_email_sent": "An email has been sent to your email address with instructions how to reset your password.",
    "reset_password_text": "Enter your email address and we will send you a link to reset your password."
  },
  "importApp": {
    "assets": {
      "description": "Please upload all files required for the import.",
      "headline": "Upload additional files",
      "list": {
        "missingTooltip": "This file is still missing",
        "show": "Show file list",
        "title": "List of required files",
        "uploadedTooltip": "This file has already been selected"
      },
      "placeholder": "Drag {'&'} Drop your file(s) or {'<'}span class='filepond--label-action'{'>'} browse {'<'}span{'>'}"
    },
    "downloadUsedImportFile": "Download",
    "fields": {
      "createdAt": "Created at",
      "creator": "Creator",
      "excelFile": "Import File",
      "recordsCount": "Linked Records",
      "status": "Status"
    },
    "reset": "Restart",
    "resetConfirmation": {
      "header": "Confirm",
      "message": "Are you sure you want to reset the current status?"
    },
    "status": {
      "draft": "Draft",
      "failed": "Failed",
      "running": "Running",
      "succeeded": "Succeeded",
      "undone": "Undone"
    },
    "submit": "Start import",
    "validationFailed": {
      "errorLine": "Row {row}:",
      "headline": "Validation failed",
      "message": "The Excel file is at least partially incorrect. Below you will find a list of the errors found:"
    },
    "validationSuccessMessage": "The Excel file has been successfully validated!",
    "validationUploader": {
      "placeholder": "Drag {'&'} Drop Excel file or {'<'}span class=\"filepond--label-action\"{'>'} browse {'<'}/span{'>'}"
    }
  },
  "login_page": {
    "footer_legal": "Legal notice",
    "footer_privacy": "Privacy policy",
    "forgot_password": "Forgot Password?",
    "hero_paragraph": "Find exclusive interests and services for you wherever you are and get many benefits!",
    "hero_title": "Your region within reach.",
    "login": "Log in",
    "password_change_success": "Password changed successfully. Please log in again with updated credentials.",
    "redirect_notice": "You will be forwarded after login.",
    "register": "Don't have an account? Create one here",
    "title": "Login",
    "unauthorized_logout": "You have been logged out automatically, please log in again."
  },
  "notifications": {
    "categories_fail": "Failed to fetch event categories",
    "clipboard_success": "Copied to clipboard",
    "delete_success": "Resource deleted successfully",
    "error": "Error",
    "fail": "Failure",
    "info": "Information",
    "invalid_form": "Invalid form, please check the fields",
    "location_updated": "Location updated successfully",
    "no_changes": "No changes made",
    "options_fail": "Failed to fetch event options",
    "reset_email_sent": "Password reset email sent to {email}",
    "success": "Success"
  },
  "offer_management": {
    "create_title": "Create offer",
    "edit_title": "Edit offer",
    "menu": {
      "partner_offers": "Partner offers"
    },
    "submit_success": "Offer has been successfully submitted for review"
  },
  "organizer": {
    "address": "Address",
    "city": "City",
    "countryCode": "Country",
    "email": "Email",
    "emergencyTel": "Emergency telephone",
    "tel": "Telephone",
    "title": "Title",
    "zip": "Zip code"
  },
  "recurrenceSettings": {
    "customTimes": {
      "cta": "Adjust start times",
      "ctaMissingStartTooltip": "Please set the start date and time of the event first",
      "modal": {
        "headline": "Adjust start times"
      },
      "perDate": {
        "addCta": "Add entry",
        "date": "Date",
        "headline": "Adjust start time by date",
        "time": "Time"
      },
      "perWeekday": {
        "headline": "Adjust start time by weekday",
        "isDefaultWeekdayHint": "Change the start date of the event to adjust this day of the week"
      },
      "readOnlyCta": "Show adjusted start times"
    },
    "end": {
      "dateRequiredMessage": "Please select a date",
      "headline": "End",
      "repeatsLabel": "dates",
      "typeLabels": {
        "never": "Never",
        "on_date": "On",
        "repeats": "After"
      }
    },
    "eventOccurrenceTypes": {
      "oneTime": "one-time",
      "recurring": "recurring"
    },
    "interval": {
      "every": "Every",
      "units": {
        "day": "Day | Days",
        "month": "Month | Months",
        "week": "Week | Weeks",
        "year": "Year | Years"
      },
      "weekdaysLabel": "Repeat on"
    },
    "submitCta": "Done"
  },
  "registration": {
    "are_you": "Are you",
    "change_image": "Change image",
    "create_organization": "Create organization",
    "data_processing": "I agree to the {link}",
    "data_processing_error": "You must agree to the processing of your personal data",
    "data_processing_link": "Data protection notice for content provider",
    "details_subtitle": "Enter your user information",
    "details_title": "User details",
    "failure_disc": "Something went wrong",
    "failure_title": "Request failed",
    "partner_bank": "Partner",
    "partner_bank_disclaimer": " Select bank partner is affiliated with",
    "paths": {
      "done": "Done",
      "options": {
        "partner": "Partner"
      },
      "partner_bank": "Bank / Partner",
      "user_details": "User details"
    },
    "role_options": {
      "event_manager": "an event manager",
      "partner": "a partner manager"
    },
    "select_bank": "Bank",
    "select_bank_disclaimer": "Select your bank",
    "success_instructions": "Check your email for further instructions how to activate your account.",
    "success_message": "Registration has been successful.",
    "terms_and_conditions": "I agree to the {link}",
    "terms_and_conditions_error": "You must agree to the terms and conditions",
    "terms_and_conditions_link": "Terms and conditions",
    "user_created": "The user has been created",
    "user_created_message": "Please await till the bank employee accepts your request."
  },
  "review": {
    "accept": "Accept",
    "accepted": "Accepted",
    "decline": "Decline",
    "declined": "Declined",
    "declineMessage": "Message",
    "event": "Event",
    "fields": {
      "city": "City",
      "contact": "Contact",
      "description": "Description",
      "details": "Details",
      "discount_debit": "Discount",
      "email": "Email",
      "link": "Link",
      "price": "Price",
      "role": "Role",
      "state": "Current state",
      "street": "Street",
      "type": "Type",
      "validity_start": "Starting date",
      "zip": "Zip code"
    },
    "nav": {
      "events": "Events",
      "offer": "Offer",
      "offers": "Offers",
      "organizer": "Attached organizer",
      "partner": "Attached Partner",
      "user": "User",
      "users": "Users"
    },
    "offer_title": "Review offer",
    "organizer": "Organizer",
    "registrationTitle": "Review user registration",
    "title": "Review event",
    "userConfirmed": "User confirmed",
    "venue": "Venue"
  },
  "routes": {
    "BankManagement": "Bank Management",
    "BankProfile": "Bank Profile",
    "ContentFeed": "News",
    "EventManagement": "Events",
    "PartnerOfferManagement": "Offers",
    "RealEstatePage": "Real Estate",
    "ReviewPage": "Review Page",
    "UserManagement": "Users",
    "VideoCallPage": "Video Call"
  },
  "trpc_error": {
    "CANNOT_DELETE_LAST_BANK_ADMIN": "You are the last administrator of your bank. You can only delete your account once a new administrator has been created.",
    "EMAIL_EXISTS": "Email already exists",
    "ENTITY_IN_USE": "Can't proceed. Current resource is in use",
    "FORBIDDEN": "Insufficient permissions for this action",
    "PHONE_EXISTS": "Phone already exists",
    "UNKNOWN_ERROR": "Unknown error",
    "USER_NOT_FOUND": "User not found"
  },
  "user_management": {
    "fields": {
      "email": "E-mail address",
      "first_name": "First name",
      "roles": "Roles",
      "surname": "Last name"
    },
    "headlines": {
      "createUser": "Create new User",
      "editUser": "Edit User"
    },
    "menu": {
      "users": "Users"
    },
    "no_users_found": "No users found",
    "reset_password": "Send reset password email",
    "roles": {
      "bank_admin": "Bank admin",
      "content_admin": "Content Admin",
      "content_feed_agent": "Content feed agent",
      "event_manager": "Event manager",
      "organizer_agent": "Organizer agent",
      "organizer_manager": "Organizer manager",
      "partner_agent": "Partner agent",
      "partner_manager": "Partner manager",
      "real_estate_agent": "Real Estate Agent",
      "system_admin": "System admin",
      "venue_manager": "Venue manager",
      "videocall_agent": "Video Call Agent"
    }
  },
  "userMenu": {
    "profile": "Profile"
  },
  "userProfile": {
    "sections": {
      "actions": {
        "deleteAccount": {
          "confirmation": {
            "detail": "Your account has been deleted.",
            "summary": "Success"
          },
          "cta": "Delete account",
          "header": "Delete account",
          "message": "Are you sure you want to delete your account?"
        },
        "deleteAvatar": {
          "confirmation": {
            "detail": "Your avatar has been deleted.",
            "summary": "Success"
          },
          "header": "Delete Avatar?",
          "message": "Are you sure you want to delete your avatar?"
        },
        "headline": "Account Actions",
        "resetPassword": {
          "confirmation": {
            "detail": "An email has been sent to your email address with instructions how to change your password.",
            "summary": "Success"
          },
          "cta": "Change password"
        }
      },
      "personalInformation": {
        "headline": "Personal Information"
      },
      "settings": {
        "headline": "Settings",
        "locale": "Locale"
      }
    },
    "title": "Profile"
  },
  "validation_errors": {
    "country_code": "Country chosen is outside of Europe or doesn't exist",
    "digit": "Password must contain at least one digit .",
    "email": "This field must be a valid e-mail",
    "event_end": "Event end date must be after event start date",
    "event_start": "Event start date cannot be before event opening date",
    "is_not_email": "This field is not a valid email address",
    "is_not_number": "This field is not a number",
    "lowercase": "Password must contain at least one lowercase letter.",
    "max": "This field must have at most {requiredLength} characters",
    "min": "This field must have at least {requiredLength} characters",
    "nonempty": "This field is required",
    "pwd_no_match": "Passwords do not match",
    "range_date": "The event has to have an end date",
    "special": "Password must contain at least one special character.",
    "too_big_string": "This field must have at most {requiredLength} characters",
    "uppercase": "Password must contain at least one uppercase letter.",
    "url": "This field must be a valid url"
  },
  "venue": {
    "address": "Address",
    "city": "City",
    "countryCode": "Country",
    "lat": "Latitude",
    "lng": "Longitude",
    "title": "Title",
    "zip": "Zip code"
  },
  "venue_management": {
    "changes_in_events": {
      "confirmation": {
        "cta": "Confirm changes in {count} Events",
        "header": "Confirm changes in Events",
        "message": "The following Events are assigned to this Venue. Confirm updates in related Events fields."
      }
    }
  },
  "videoCall": {
    "device": {
      "audioinput": "Audio input (microphone)",
      "audiooutput": "Audio output",
      "videoinput": "Camera"
    },
    "permissionError": {
      "detail": "Access to the camera and/or microphone has been denied, please check your browser settings",
      "summary": "Permission error"
    },
    "preview": {
      "previewClickHint": "click to show preview",
      "videoError": {
        "summary": "Error when creating the camera preview"
      }
    },
    "settings": {
      "headline": "Settings"
    },
    "status": {
      "joining": "Joining call...",
      "leaving": "Leaving call...",
      "offline": "Offline",
      "online": "Online",
      "ringing": "Incoming call"
    }
  }
}
